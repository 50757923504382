<template>
  <b-card-code title="Create a new inventory/website">
    <b-overlay 
      :show="show" 
      rounded="lg" 
      no-fade 
    >
        <div :aria-hidden="show ? 'true' : null">
            <b-form 
                ref="form"
                class="mt-1"
                @submit.prevent="createInventoryForm"
            >
                <b-row>
                    <b-col md="8">
                        <b-row>
                            <!-- Inventory Type -->
                            <b-col md="12">
                                <b-form-group
                                    label="Inventory Type"
                                    label-for="inventory_type"
                                >
                                    <v-select
                                        v-model="inventory_type"
                                        :reduce="(option) => option.id"
                                        :options="inventories"
                                        @input="inventory_input"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- Site Domain -->
                            <b-col md="6">
                                <b-form-group
                                    label="Site Domain"
                                    label-for="inventory_domain"
                                >
                                    <b-input-group>            
                                        <b-form-input 
                                            placeholder="example.com" 
                                            v-model="inventory_domain"
                                            v-on:keyup="inventoryDomain"
                                            v-on:focusout="domainPreview"
                                            :state="validDomain(inventory_domain)"
                                            :class="validDomain(inventory_domain)==false?'text-danger':'text-success'"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- Inventory Name -->
                            <b-col md="6">
                                <b-form-group
                                    label="Inventory Name"
                                    label-for="inventory_name"
                                >
                                    <b-input-group>            
                                    <b-form-input 
                                        placeholder="Inventory Name" 
                                        name="inventory_name" 
                                        v-model="inventory_name"
                                        :class="validDomain(inventory_domain)==false?'text-danger':'text-success'"
                                    />
                                    <b-input-group-append is-text>
                                        <b-form-checkbox
                                        v-model="inventory_status"
                                        :checked="inventory_status"
                                        switch
                                        inline
                                    >
                                    </b-form-checkbox>
                                    </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- IAB Categories -->
                            <b-col md="6">
                                <b-form-group
                                    label="IAB Categories"
                                    label-for="slot_iab_categories"
                                >
                                    <v-select
                                        :reduce="(option) => option.name"
                                        :options="iab_categories_list"
                                        v-model="slot_iab_categories"
                                        multiple
                                        :selectable="() => slot_iab_categories.length < 6"
                                        @input="updateIABCats"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- IAB Sub Categories -->
                            <b-col md="6">
                                <b-form-group
                                    label="IAB Sub Categories"
                                    label-for="slot_iab_sub_categories"
                                >              
                                    <v-select
                                        :reduce="(option) => option.name"
                                        :options="iab_sub_categories_list"
                                        v-model="slot_iab_sub_categories"
                                        multiple
                                        :disabled="iabsubdisabled"
                                        :selectable="() => slot_iab_sub_categories.length < 12"
                                    >
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <!-- Content Language-->
                            <b-col md="6">
                                <b-form-group
                                    label="Content Language"
                                    label-for="language"
                                >
                                    <v-select
                                    :reduce="(option) => option.code"
                                    :options="languages_list"
                                    v-model="language"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="12"></b-col> 
                            <!--  -->

                            <b-col md="12"> 
                                <b-card-code title="More Options">
                                    <app-collapse>

                                        <app-collapse-item title="Privacy Options">
                                            <b-row>
                                                <b-col md="6">

                                                    <b-col cols="12" class="mb-1">
                                                        <b-form-checkbox
                                                            id="mobile_optimized"
                                                            :checked="mobile_optimized"
                                                            switch
                                                            inline
                                                        >
                                                            <span>Mobile Optimized Layout</span>
                                                        </b-form-checkbox>
                                                    </b-col>

                                                    <b-col cols="12" class="mb-1">
                                                        <b-form-checkbox
                                                            id="gdpr"
                                                            v-model="gdpr"
                                                            :checked="gdpr"
                                                            switch
                                                            inline
                                                        >
                                                            <span>GDPR compliant</span>
                                                        </b-form-checkbox>
                                                    </b-col>

                                                    <b-col cols="12" class="mb-1">
                                                        <b-form-checkbox
                                                            id="coppa"
                                                            v-model="coppa"
                                                            :checked="coppa"
                                                            switch
                                                            inline
                                                        >
                                                            <span>Coppa</span>
                                                        </b-form-checkbox>  
                                                    </b-col>                                          

                                                </b-col> 
                                                <b-col md="6">

                                                    <!-- Privacy Policy URL -->
                                                    <b-col md="12">
                                                        <b-form-group
                                                            label="Privacy Policy URL"
                                                            label-for="privacy"
                                                        >
                                                            <b-input-group>            
                                                                <b-form-input 
                                                                placeholder="http://example.com/privacy-policy" 
                                                                v-model="privacy" 
                                                                />
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-col>

                                                </b-col> 
                                            </b-row>                                    
                                        </app-collapse-item>

                                        <app-collapse-item title="Description">
                                            <b-row>
                                                <b-col class="12">
                                                    <!-- Inventory Description -->
                                                    <b-form-textarea
                                                        label="Description"
                                                        v-model="inventory_description"
                                                        placeholder="Description"
                                                        rows="3"
                                                        :state="inventory_description.length <= maxChar"
                                                        class="char-textarea"
                                                        :class="inventory_description.length >= maxChar ? 'text-danger' : ''"
                                                    />
                                                    <small
                                                        class="textarea-counter-inventory_description float-right"
                                                        :class="inventory_description.length >= maxChar ? 'bg-danger' : ''"
                                                    >
                                                        <span class="char-count">{{ inventory_description.length }}</span> / {{ maxChar }}
                                                    </small>

                                                </b-col>
                                            </b-row>
                                        </app-collapse-item>

                                    </app-collapse>
                                </b-card-code>
                            </b-col> 

                            <b-col class="12">
                                <div class="card-header">
                                    <h4 class="card-title">Create Ad Slots</h4>
                                </div>
                            </b-col>


                            <b-col md="12">                        
                                <b-row
                                    v-for="(item, index) in items"
                                    :id="item.index"
                                    :key="item.index"
                                    ref="row"
                                >
                                    <b-card-code :title="item.title" >
                                    <b-row>
                                        <!-- Ad Slot Name -->
                                        <b-col md="6">
                                            <b-form-group
                                                label="Ad Slot Name"
                                                :label-for="item.slot_name"
                                            >
                                                <b-input-group>            
                                                    <b-form-input
                                                        placeholder="Slot Name"
                                                        v-model="item.slot_name"
                                                    />
                                                </b-input-group>
                                            </b-form-group>
                                        </b-col>

                                        <!-- Ad Format -->
                                        <b-col md="6">
                                            <b-form-group
                                                label="Ad Format"
                                                label-for="ad_format"
                                            >
                                                <v-select
                                                    v-model="item.ad_format"
                                                    :reduce="(option) => option.id"
                                                    :options="ad_format"
                                                />
                                            </b-form-group>
                                        </b-col>

                                    <!-- Slot Position -->
                                    <b-col md="6">
                                        <b-form-group
                                            label="Slot Position"
                                            label-for="slot_position"
                                        >
                                            <v-select
                                                :reduce="(option) => option.id"
                                                :options="slot_position"
                                                v-model="item.slot_position"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    

                                    <!-- Responsive Type -->
                                    <b-col md="6">
                                        <b-form-group
                                            label="Responsive Type"
                                            label-for="responsive"
                                        >
                                            <v-select
                                                :reduce="(option) => option.id"
                                                :options="responsive"
                                                v-model="item.responsive"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <!-- Desktop Template Template -->
                                    <b-col md="6">
                                        <b-form-group
                                            label="Desktop Template Template"
                                            label-for="desktop_temp"
                                        >
                                            <v-select
                                                :reduce="(option) => option.id"
                                                :options="desktop_templates_list"
                                                v-model="item.desktop_temp"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <!-- Mobile Template -->
                                    <b-col md="6">
                                        <b-form-group
                                            label="Mobile Template"
                                            label-for="mobile_temp"
                                        >
                                            <v-select
                                                :reduce="(option) => option.id"
                                                :options="mobile_templates_list"
                                                v-model="item.mobile_temp"
                                            />
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                    <b-form-textarea
                                        v-model="item.desktop_pb"
                                        placeholder="Desktop Passback"
                                        rows="3"
                                        :state="item.desktop_pb.length <= maxChar"
                                        class="char-textarea"
                                        :class="item.desktop_pb.length >= maxChar ? 'text-danger' : ''"
                                    />
                                    <small
                                        class="textarea-counter-desktop_pb float-right"
                                        :class="item.desktop_pb.length >= maxChar ? 'bg-danger' : ''"
                                    >
                                        <span class="char-count">{{ item.desktop_pb.length }}</span> / {{ maxChar }}
                                    </small>
                                    </b-col>

                                    <b-col md="6">
                                    <b-form-textarea
                                        v-model="item.mobile_pb"
                                        placeholder="Mobile Passback"
                                        rows="3"
                                        :state="item.mobile_pb.length <= maxChar"
                                        class="char-textarea"
                                        :class="item.mobile_pb.length >= maxChar ? 'text-danger' : ''"
                                    />
                                    <small
                                        class="textarea-counter-mobile_pb float-right"
                                        :class="item.mobile_pb.length >= maxChar ? 'bg-danger' : ''"
                                    >
                                        <span class="char-count">{{ item.mobile_pb.length }}</span> / {{ maxChar }}
                                    </small>
                                    </b-col>

                                    <!-- Remove Button -->
                                    <b-col
                                        lg="2"
                                        md="3"
                                        class="mb-50"
                                        v-if="index > 0 "
                                    >
                                        <b-button
                                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                            variant="outline-danger"
                                            class="mt-0 mt-md-2"
                                            @click="removeItem(index)"
                                        >
                                        <feather-icon
                                            icon="XIcon"
                                            class="mr-25"
                                        />
                                        <span>Delete</span>
                                        </b-button>
                                    </b-col>
                                    <b-col cols="12">
                                        <hr>
                                    </b-col>
                                    </b-row>
                                    </b-card-code>
                                </b-row>
                                <b-row>
                                    <!-- Form Repeater Button -->
                                    <b-col cols="12" class="mb-5 mx-5">
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="primary"
                                            @click="repeateAgain"
                                        >
                                            <feather-icon
                                                icon="PlusIcon"
                                                class="mr-25"
                                            />
                                            <span>Add New</span>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="6"></b-col>

                            <!-- submit and reset -->
                            <b-col cols="12" class="my-5 text-center">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                type="submit"
                                variant="primary"
                                class="mr-1"
                            >
                                Submit
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="reset"
                                variant="outline-secondary"
                                @click="reset"
                            >
                                Reset
                            </b-button>
                            </b-col>
                        </b-row>

                    </b-col>
                    <b-col md="4">
                        <!-- right sidebar domain preview section -->
                        <h2>Preview</h2>
                        <span v-html="inventory_preview"></span>
                    </b-col>
                </b-row>
            
            </b-form>
        </div>
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow,
  BCol,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormValidFeedback,
  BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormText,
    BButton,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple
  },
  data() {
    return {
      inventory_type:{label:'Website',id:0},
      inventories: [
        {label :'Website',id:0}, 
        {label :'Android App',id:1}, 
        {label :'iOS App',id:2}
      ],
      inventory_domain:'',
      inventory_name:'',
      inventory_status:true,
      slot_iab_categories:'',
      slot_iab_sub_categories:'',
      iabsubdisabled:true,
      language:'',
      iab_categories_list:[],
      iab_sub_categories_list:[],
      languages_list:[],
      mobile_optimized:true,
      gdpr:true,
      coppa:true,
      privacy:'',
      inventory_description:'',
      ad_format:[ 
        {label : 'Banner', id:1}, 
        {label : 'Video', id:2}, 
        {label : 'Native', id:3} 
      ],
      slot_position:[ 
        {label : 'Unknown', id:1}, 
        {label : 'Above the fold', id:2}, 
        {label : 'Below the fold', id:3},
        {label : 'Header', id:4},
        {label : 'Footer', id:5},
        {label : 'Sidebar', id:6},
        {label : 'Fullscreen', id:7}
      ],
      responsive:[ 
        {label : 'Responsive', id : 1}, 
        {label : 'Desktop Only',id : 2}, 
        {label : 'Mobile Only', id:3} 
      ],
      maxChar: 500,
      items: [{
        id: "1",
        title:'Ad Slot 1',
        slot_name:'',
        ad_format:{label : 'Banner', id:1},
        slot_position: {label : 'Above the fold', id:2},
        responsive: {label : 'Responsive', id : 1},
        desktop_temp: {"id": "1","label": "300×250 Medium Rectangle"},
        mobile_temp: {"id": "1","label": "300×250 Medium Rectangle"},
        desktop_pb: '',
        mobile_pb: '',
      }],
      nextTodoId: 1,
      ad_slot_id: 1,      
      inventory_preview:'',
      desktop_templates_list:[],
      mobile_templates_list:[],
      inventory_id:0,
      // form submission process handle
      show: false,
      interval: null,
    }
  },
  methods: {
    inventory_input(id){
        this.inventory_id=id;
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += 1,
        title:'Ad Slot ' + (this.nextTodoId),
        slot_name:'',
        ad_format:{label : 'Banner', id:1},
        slot_position: {label : 'Above the fold', id:2},
        responsive: {label : 'Responsive', id : 1},
        desktop_temp: {"id": "1","label": "300×250 Medium Rectangle"},
        mobile_temp: {"id": "1","label": "300×250 Medium Rectangle"},
        desktop_pb: '',
        mobile_pb: '',
      });
      this.ad_slot_id+=1;
      this.title = 'Ad Slot ' + this.ad_slot_id;
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    reset(){
        console.log('testing reset');
    },
    inventoryDomain(){
        this.inventory_name=this.inventory_domain.replace(/(^\w+:|^)\/\//, '');
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    createInventoryForm(){
        this.show = true;
        const accessToken = useJwt.getToken('accessToken');
         let form_new_data = {
            inventory_type: this.inventory_id,
            inventory_domain: this.inventory_domain,
            inventory_name: this.inventory_name,
            inventory_status: this.inventory_status==true?1:0,
            slot_iab_categories: this.slot_iab_categories.join(","),
            slot_iab_sub_categories: this.slot_iab_sub_categories.join(","),
            language: this.language,
            mobile_optimized: this.mobile_optimized==true?1:0,
            gdpr: this.gdpr==true?1:0,
            coppa: this.coppa==true?1:0,
            privacy:this.privacy,
            inventory_description:this.inventory_description,
            repeater_list:this.items
        };

        this.interval = setInterval(() => {
            this.$http.post('https://api.purplepatch.online/publisher/inventory/create/', form_new_data, { 
                headers: {
                    Authorization:'Bearer ' + accessToken,
                    'x-access-token': accessToken 
                } 
            })
            .then(response => {
                this.show = false;
                this.clearInterval();
                if( response.status == '201' ){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: response.data.msg,
                            icon: 'AwardIcon',
                            variant: 'success',
                            text: response.data.msg
                        },
                    });
                    return router.push({ name: 'inventory' });
                }
                if( response.status != '201' ){
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: response.data.msg,
                            icon: 'BellOffIcon',
                            variant: 'error',
                            text: response.data.msg
                        },
                    });
                    return router.push({ name: 'inventory' });
                }
                console.log(response);
            })
            .catch(error => {
            this.show = false;
            this.clearInterval();
                // if( response.status != '201' ){
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: error.msg,
                        icon: 'CoffeeIcon',
                        variant: 'error',
                        text: error.msg
                    },
                    });
                    // return router.push({ name: 'inventory' });
                // }
            });
        }, 350)
    },
    validDomain(domain){
        return (/^(?:(http:\/\/|https:\/\/))?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z0-9\/]{2,})+$/.test(domain)) ? true : false;
    },
    domainPreview(){
        const accessToken = useJwt.getToken('accessToken')
        this.$http.post('https://api.purplepatch.online/getsnapshot', {'inventory_domain':this.inventory_name,delay: 2}, {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
      .then(res => {
            if(res.data){
                this.inventory_preview= '<img src="//purplepatch.online'+res.data.screenshot+'" alt="'+this.inventory_domain+' preview" style="max-width:100%" />';
            }
            console.log(res);
        })
    },
    updateIABCats(data){
        console.log(this.slot_iab_sub_categories);
        const accessToken = useJwt.getToken('accessToken')
        this.$http.post('https://api.purplepatch.online/getiabsubcategories', {'iab_categories':data}, {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
      .then(res => {            
            if(res.data){                
                this.iabsubdisabled=false;
                this.iab_sub_categories_list=res.data;
            }
        })
    }
  },
  created() {
        const accessToken = useJwt.getToken('accessToken')
        this.$http.get('https://api.purplepatch.online/inventory-form-info', {
            headers: {
                Authorization:'Bearer ' + accessToken,
                'x-access-token': accessToken 
            }
        })
        .then(res => { 
            this.iab_categories_list = res.data.iab_categories_list;
            this.languages_list = res.data.languages_list;
            this.desktop_templates_list = res.data.desktop_templates_list;
            this.mobile_templates_list = res.data.mobile_templates_list;
            console.log(res.data);
        });
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


